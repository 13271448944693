// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/client/themes/Test/assets/fonts/SuisseIntl-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{--color-theme-main: #DB4C94;--color-theme-main-dark: #a0135a;--color-theme-dark-light: #777E90;--color-theme-dark: #091C47;--color-theme-darkest: #091C47;--color-theme-lightest: #FCFCFD;--color-theme-default-text: #fff}@font-face{font-family:"SuisseIntl";src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype")}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/assets/sass/base/_colors.sass","webpack://./src/client/themes/Test/assets/sass/base/fonts/_general.sass"],"names":[],"mappings":"AAAA,MACE,2BAAA,CACA,gCAAA,CACA,iCAAA,CACA,2BAAA,CACA,8BAAA,CACA,+BAAA,CACA,gCAAA,CCPF,WACG,wBAAA,CACA,8DAAA","sourcesContent":[":root\n  --color-theme-main: #DB4C94\n  --color-theme-main-dark: #a0135a\n  --color-theme-dark-light: #777E90\n  --color-theme-dark: #091C47\n  --color-theme-darkest: #091C47\n  --color-theme-lightest: #FCFCFD\n  --color-theme-default-text: #fff\n","@font-face\n   font-family: 'SuisseIntl'\n   src: url(\"@/client/themes/Test/assets/fonts/SuisseIntl-Regular.ttf\") format(\"truetype\")\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
