// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RpdtrGDF3Uc5ei6WthWn{width:100% !important;height:100vh !important;top:0 !important}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/TokenDetailsTemplate/components/AssetsSection/components/ModalPreview/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,uBAAA,CACA,gBAAA","sourcesContent":[".fullScreen\n  width: 100% !important\n  height: 100vh !important\n  top: 0 !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullScreen": `RpdtrGDF3Uc5ei6WthWn`
};
export default ___CSS_LOADER_EXPORT___;
