// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wvoBST1jtIxWff46AuKK{background:#fafafa;padding:.75rem 2.75rem}.yp2loCjW604qHple4p6p{color:#000;font-weight:600;background-color:#fff;border:1px solid #e6e7e8 !important;cursor:pointer}.yp2loCjW604qHple4p6p:hover{color:#fff;background-color:var(--color-theme-dark)}.VB3_d0t2TF_TPC9GyYSI{color:#000;background-color:var(--color-theme-main);padding:0 16px}.VB3_d0t2TF_TPC9GyYSI:hover{background-color:var(--color-theme-main-dark)}.fkLIKkCtra49MMpS1oem{font-family:SuisseIntl !important;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/SatTemplate/components/OffersLists/components/ModalAcceptOffer/components/FooterModal/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CACA,sBAAA,CACF,sBACE,UAAA,CACA,eAAA,CACA,qBAAA,CACA,mCAAA,CACA,cAAA,CACA,4BACE,UAAA,CACA,wCAAA,CACJ,sBACE,UAAA,CACA,wCAAA,CACA,cAAA,CACA,4BACE,6CAAA,CACJ,sBACE,iCAAA,CACA,wBAAA","sourcesContent":["@import \"@/client/assets/sass/base/spacing\"\n\n.footer\n  background: #FAFAFA\n  padding: $spacing-sm $spacing-xlg\n.btnCancel\n  color: #000\n  font-weight: 600\n  background-color: #fff\n  border: 1px solid #e6e7e8 !important\n  cursor: pointer\n  &:hover\n    color: #fff\n    background-color: var(--color-theme-dark) \n.btnClaim\n  color: #000\n  background-color: var(--color-theme-main)\n  padding: 0 16px\n  &:hover\n    background-color: var(--color-theme-main-dark)\n.btnOrgTxt\n  font-family: SuisseIntl !important\n  text-transform: uppercase"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `wvoBST1jtIxWff46AuKK`,
	"btnCancel": `yp2loCjW604qHple4p6p`,
	"btnClaim": `VB3_d0t2TF_TPC9GyYSI`,
	"btnOrgTxt": `fkLIKkCtra49MMpS1oem`
};
export default ___CSS_LOADER_EXPORT___;
