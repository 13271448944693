// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L1FZzV6Q6STUy9nYa3Yg{color:var(--color-theme-default-text);padding-top:65px;padding-bottom:65px;font-family:SuisseIntl !important;text-transform:uppercase}.L1FZzV6Q6STUy9nYa3Yg button,.L1FZzV6Q6STUy9nYa3Yg input,.L1FZzV6Q6STUy9nYa3Yg select,.L1FZzV6Q6STUy9nYa3Yg h1,.L1FZzV6Q6STUy9nYa3Yg h2,.L1FZzV6Q6STUy9nYa3Yg h3{font-family:SuisseIntl !important;text-transform:uppercase}@media(max-width: 768px){.L1FZzV6Q6STUy9nYa3Yg{padding-top:0}}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/wrappers/MainContent/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,qCAAA,CACA,gBAAA,CACA,mBAAA,CACA,iCAAA,CACA,wBAAA,CACA,iKAEE,iCAAA,CACA,wBAAA,CCDF,yBDRF,sBAWI,aAAA,CAAA","sourcesContent":["@import \"src/client/themes/Test/assets/sass/base/_breakpoints.sass\"\n\n.main  \n  color: var(--color-theme-default-text)\n  padding-top: 65px\n  padding-bottom: 65px\n  font-family: SuisseIntl !important\n  text-transform: uppercase\n  button, input, select,\n  h1, h2, h3\n    font-family: SuisseIntl !important\n    text-transform: uppercase\n  @include medium-screen\n    padding-top: 0","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `L1FZzV6Q6STUy9nYa3Yg`
};
export default ___CSS_LOADER_EXPORT___;
