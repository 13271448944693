// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ahwb_yvzEuKgu4zLFYby{border:2px solid var(--color-theme-dark-light)}.NHYvZOhnBQ1dKKr5btuR,.eF9KgGtjfvuWKaCqkOcr{padding-bottom:14px;padding-left:16px;padding-right:16px;padding-top:14px}.eF9KgGtjfvuWKaCqkOcr{align-items:center;background:rgba(0,0,0,0);border:none;cursor:pointer;display:inline-flex;justify-content:center}.fLL313k_QuOx0w4_QBAT{opacity:.5;cursor:default !important}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/assets/sass/base/_forms.sass","webpack://./src/client/themes/Test/components/atoms/buttons/ButtonBase/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,8CAAA,CACF,4CACE,mBAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA,CCJF,sBACE,kBAAA,CACA,wBAAA,CACA,WAAA,CACA,cAAA,CACA,mBAAA,CACA,sBAAA,CAEF,sBACE,UAAA,CACA,yBAAA","sourcesContent":[".defFormBorders\n  border: 2px solid var(--color-theme-dark-light)\n.defFormPaddings\n  padding-bottom: 14px\n  padding-left: 16px\n  padding-right: 16px\n  padding-top: 14px\n","@import \"../../../../assets/sass/base/_forms\"\n\n.button\n  align-items: center\n  background: transparent\n  border: none\n  cursor: pointer\n  display: inline-flex\n  justify-content: center\n  @extend .defFormPaddings\n.isDisabled\n  opacity: 0.5\n  cursor: default !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defFormBorders": `ahwb_yvzEuKgu4zLFYby`,
	"defFormPaddings": `NHYvZOhnBQ1dKKr5btuR`,
	"button": `eF9KgGtjfvuWKaCqkOcr`,
	"isDisabled": `fLL313k_QuOx0w4_QBAT`
};
export default ___CSS_LOADER_EXPORT___;
