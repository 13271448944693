// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BesvcvNmbFuC3tcK3J99{font-family:SuisseIntl !important;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/SatTemplate/components/OffersLists/components/ModalAcceptOffer/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iCAAA,CACA,wBAAA","sourcesContent":[".modalOrg\n  font-family: SuisseIntl !important\n  text-transform: uppercase\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOrg": `BesvcvNmbFuC3tcK3J99`
};
export default ___CSS_LOADER_EXPORT___;
