// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OACYlfJ1t8g6OPxPyhqg{display:flex;flex-direction:column;border-width:1px;border-style:solid;border-color:var(--ct-accent-one);align-items:center;padding:3px;min-width:50px;min-height:55px;justify-content:center}.s9X8X51TX6xt3lgSmjai{color:var(--ct-accent-two)}.ZcapeILPxUd_px7QNcwH{color:var(--ct-text-color-primary);font-size:25px;line-height:1}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/CollectionTemplate/components/CategoriesSections/component/CategoryContent/components/Section/component/Header/component/NumberItem/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,gBAAA,CACA,kBAAA,CACA,iCAAA,CACA,kBAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CACA,sBAAA,CACF,sBACE,0BAAA,CACF,sBACE,kCAAA,CACA,cAAA,CACA,aAAA","sourcesContent":[".numberItem\n  display: flex\n  flex-direction: column\n  border-width: 1px\n  border-style: solid\n  border-color: var(--ct-accent-one)\n  align-items: center\n  padding: 3px\n  min-width: 50px\n  min-height: 55px\n  justify-content: center\n.label\n  color: var(--ct-accent-two)\n.number\n  color: var(--ct-text-color-primary)\n  font-size: 25px\n  line-height: 1\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberItem": `OACYlfJ1t8g6OPxPyhqg`,
	"label": `s9X8X51TX6xt3lgSmjai`,
	"number": `ZcapeILPxUd_px7QNcwH`
};
export default ___CSS_LOADER_EXPORT___;
