// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wa2JKvIDIMyWsBxSBROt{text-align:center}.Z2lPkfiu2V860HuAYFdx{min-width:100px}.PJZmNvhqSevBNFipBGRH{position:relative;width:216px !important;height:167px !important;overflow:hidden !important;margin-bottom:10px;margin-right:20px;border-radius:16px;cursor:pointer}.SK6hmWmAOPi5qD19VHa0{width:216px !important;height:311px !important;position:absolute;top:-42px;left:0;right:0;bottom:0;object-fit:cover}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/components/AssetCard/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACF,sBACE,eAAA,CACF,sBACE,iBAAA,CACA,sBAAA,CACA,uBAAA,CACA,0BAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,cAAA,CACF,sBACI,sBAAA,CACA,uBAAA,CACA,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,gBAAA","sourcesContent":["@import \"../../../../../assets/sass/base/_breakpoints.sass\"\n\n.tagList\n  text-align: center\n.primaryButton\n  min-width: 100px\n.imgContainer\n  position: relative\n  width: 216px !important\n  height: 167px !important\n  overflow: hidden !important\n  margin-bottom: 10px\n  margin-right: 20px\n  border-radius: 16px  \n  cursor: pointer\n.mainImage\n    width: 216px !important\n    height: 311px !important\n    position: absolute\n    top: -42px\n    left: 0\n    right: 0\n    bottom: 0\n    object-fit: cover      \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tagList": `wa2JKvIDIMyWsBxSBROt`,
	"primaryButton": `Z2lPkfiu2V860HuAYFdx`,
	"imgContainer": `PJZmNvhqSevBNFipBGRH`,
	"mainImage": `SK6hmWmAOPi5qD19VHa0`
};
export default ___CSS_LOADER_EXPORT___;
