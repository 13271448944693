// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zQvTDO9eOJZW6DmD8irZ{padding:0 35px}@media(max-width: 768px){.zQvTDO9eOJZW6DmD8irZ{padding:10px 15px}}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/GeneralTemplate/styles-general.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,cAAA,CCQA,yBDTF,sBAGI,iBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.paddingPage\n  padding: 0 35px\n  @include mobile\n    padding: 10px 15px","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n  @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin md\n  @media (min-width: #{$screen-md})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paddingPage": `zQvTDO9eOJZW6DmD8irZ`
};
export default ___CSS_LOADER_EXPORT___;
