// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yrzXTnt5R3ObN1_P0QdX{font-size:14px;padding-bottom:50px !important;padding-right:10px !important}._8OP59IgKd1uRVOJWNpg,.G6qq78e3Kxa7xtep06yl{line-height:1.8}._8OP59IgKd1uRVOJWNpg>*,.G6qq78e3Kxa7xtep06yl>*{display:flex}._8OP59IgKd1uRVOJWNpg{font-weight:600}.sXRowsO3OgVYwoBD3x6a{border-spacing:0 4px}.O6ewq6Ri303D8IhBg8xw{width:250px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Sat/components/BoxCertifiers/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,8BAAA,CACA,6BAAA,CACF,4CAEE,eAAA,CACA,gDACE,YAAA,CACJ,sBACE,eAAA,CACF,sBACE,oBAAA,CACF,sBACE,WAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":[".boxDetails\n  font-size: 14px\n  padding-bottom: 50px!important\n  padding-right: 10px!important\n.labels,\n.texts\n  line-height: 1.8\n  > *\n    display: flex\n.labels\n  font-weight: 600\n.table\n  border-spacing: 0 4px\n.nickname\n  width: 250px\n  white-space: nowrap\n  text-overflow: ellipsis\n  overflow: hidden\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxDetails": `yrzXTnt5R3ObN1_P0QdX`,
	"labels": `_8OP59IgKd1uRVOJWNpg`,
	"texts": `G6qq78e3Kxa7xtep06yl`,
	"table": `sXRowsO3OgVYwoBD3x6a`,
	"nickname": `O6ewq6Ri303D8IhBg8xw`
};
export default ___CSS_LOADER_EXPORT___;
