// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._Pfjie6R9gD1UYgcuoBX{padding:0}.W9MHlCMFD5P5GrJakuVc ul{table-layout:auto}.l1E8ohkKjNw14WKUsNGZ{width:100% !important}.ACyfAZ8YaF6I2m_pR0AH{border-radius:5px 5px 0 0;padding:1.25rem 2.75rem 0 2.75rem;background:#fff}`, "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Sat/components/modal/ModalPreviewAsset/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,SAAA,CAEA,yBACE,iBAAA,CACJ,sBACE,qBAAA,CACF,sBACE,yBAAA,CACA,iCAAA,CACA,eAAA","sourcesContent":["@import \"@/client/assets/sass/base/spacing\"\n\n.header\n  padding: 0\n.assetDetails\n  ul\n    table-layout: auto\n.expandPdf\n  width: 100% !important\n.modalHeader\n  border-radius: 5px 5px 0 0\n  padding: $spacing-md $spacing-xlg 0 $spacing-xlg\n  background: #fff  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `_Pfjie6R9gD1UYgcuoBX`,
	"assetDetails": `W9MHlCMFD5P5GrJakuVc`,
	"expandPdf": `l1E8ohkKjNw14WKUsNGZ`,
	"modalHeader": `ACyfAZ8YaF6I2m_pR0AH`
};
export default ___CSS_LOADER_EXPORT___;
