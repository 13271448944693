// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zJwuTT8VlytvAAsu2cKk{margin-bottom:42px}@media(min-width: 992px){.zJwuTT8VlytvAAsu2cKk{margin-bottom:65px}}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/pages/Bet/components/organisms/section-page/ImageInfo/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CCWA,yBDZF,sBAGI,kBAAA,CAAA","sourcesContent":["@import \"../../../../../../assets/sass/base/_breakpoints.sass\"\n\n.marginBottom\n  margin-bottom: 42px\n  @include large-screen\n    margin-bottom: 65px\n","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginBottom": `zJwuTT8VlytvAAsu2cKk`
};
export default ___CSS_LOADER_EXPORT___;
