// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VZJgr78vgQp5hsXca95P{padding:32px;margin-bottom:42px}.s7z_P99ODY78ghCeP6Ui{margin-bottom:16px}.nsPJNmtrVoC7EDmkhB4L{font-size:24px}.nsPJNmtrVoC7EDmkhB4L,.HduZ1hl1hj43EytCWIOC{color:var(--color-theme-default-text);text-align:center}.HduZ1hl1hj43EytCWIOC{font-weight:bold;font-size:14px}.BfHGvp5DeEUtAoemN3XP{font-weight:bold;text-align:center;margin-bottom:24px}.BfHGvp5DeEUtAoemN3XP>*{display:inline-block}.Wn7kTK17gv4_99yFRPFw{margin-right:30px}.d9PJExI9UdskC8uCOeJK{text-align:center;font-size:14px}.j0tz8GE4CqhSf9GjMFET{margin-bottom:20px}.mAfOhE30ygSXZzEMjVDz{font-weight:bold}.OkmialkeTKLtTy0l48FJ{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/pages/Bet/components/organisms/section-page/ImageInfo/components/SmallTitle/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACF,sBACE,kBAAA,CACF,sBACE,cAAA,CACF,4CAEE,qCAAA,CACA,iBAAA,CACF,sBACE,gBAAA,CACA,cAAA,CACF,sBACE,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,wBACE,oBAAA,CACJ,sBACE,iBAAA,CACF,sBACE,iBAAA,CACA,cAAA,CACF,sBACE,kBAAA,CACF,sBACE,gBAAA,CACF,sBACE,cAAA","sourcesContent":[".box\n  padding: 32px\n  margin-bottom: 42px\n.titleContainer\n  margin-bottom: 16px\n.title\n  font-size: 24px\n.title,\n.id\n  color: var(--color-theme-default-text)\n  text-align: center\n.id\n  font-weight: bold\n  font-size: 14px\n.icons\n  font-weight: bold\n  text-align: center\n  margin-bottom: 24px\n  & > *\n    display: inline-block\n.gender\n  margin-right: 30px \n.detailsBox\n  text-align: center\n  font-size: 14px\n.itemList\n  margin-bottom: 20px\n.value\n  font-weight: bold\n.iconsText\n  font-size: 14px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `VZJgr78vgQp5hsXca95P`,
	"titleContainer": `s7z_P99ODY78ghCeP6Ui`,
	"title": `nsPJNmtrVoC7EDmkhB4L`,
	"id": `HduZ1hl1hj43EytCWIOC`,
	"icons": `BfHGvp5DeEUtAoemN3XP`,
	"gender": `Wn7kTK17gv4_99yFRPFw`,
	"detailsBox": `d9PJExI9UdskC8uCOeJK`,
	"itemList": `j0tz8GE4CqhSf9GjMFET`,
	"value": `mAfOhE30ygSXZzEMjVDz`,
	"iconsText": `OkmialkeTKLtTy0l48FJ`
};
export default ___CSS_LOADER_EXPORT___;
