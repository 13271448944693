// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SNCDdos9OKaX7sTaCYqG{width:100% !important;height:100vh !important;top:0 !important}`, "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Sat/components/modal/ModaViewAsset/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,uBAAA,CACA,gBAAA","sourcesContent":[".fullScreen\n  width: 100% !important\n  height: 100vh !important\n  top: 0 !important"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullScreen": `SNCDdos9OKaX7sTaCYqG`
};
export default ___CSS_LOADER_EXPORT___;
