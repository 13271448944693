// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jtJcdF0zMSOS8dhchPAN{width:375px;margin-right:10px;flex-grow:0;flex-shrink:0;cursor:pointer}@media(min-width: 992px){.jtJcdF0zMSOS8dhchPAN{margin-right:32px}}.xVpM6jszsNKt1k8a3ACD{color:#000;background-color:var(--color-theme-main);font-weight:600;padding:0 16px}.xVpM6jszsNKt1k8a3ACD:hover{background-color:var(--color-theme-main-dark) !important;color:#000 !important}`, "",{"version":3,"sources":["webpack://./src/client/pages/Redeem/pages/CollectionOffer/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,iBAAA,CACA,WAAA,CACA,aAAA,CACA,cAAA,CCOA,yBDZF,sBAOI,iBAAA,CAAA,CACJ,sBACE,UAAA,CACA,wCAAA,CACA,eAAA,CACA,cAAA,CACA,4BACE,wDAAA,CACA,qBAAA","sourcesContent":["@import \"@/client/themes/Test/assets/sass/base/_breakpoints.sass\"\n\n.offer\n  width: 375px\n  margin-right: 10px\n  flex-grow: 0\n  flex-shrink: 0\n  cursor: pointer\n  @include large-screen\n    margin-right: 32px\n.btn1STOrg\n  color: #000\n  background-color: var(--color-theme-main)\n  font-weight: 600\n  padding: 0 16px\n  &:hover\n    background-color: var(--color-theme-main-dark) !important\n    color: #000 !important\n","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offer": `jtJcdF0zMSOS8dhchPAN`,
	"btn1STOrg": `xVpM6jszsNKt1k8a3ACD`
};
export default ___CSS_LOADER_EXPORT___;
