// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eLHmKHfZDzFTbBFpwK8M{padding-top:48px;padding-bottom:48px;display:block;justify-content:space-between;font-family:SuisseIntl !important;text-transform:uppercase}@media(min-width: 992px){.eLHmKHfZDzFTbBFpwK8M{display:flex}}@media(max-width: 768px){.eLHmKHfZDzFTbBFpwK8M{display:block;padding-top:30px}}@media(max-width: 360px){.eLHmKHfZDzFTbBFpwK8M{padding-top:10px}}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/organisms/layout/Header/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,gBAAA,CACA,mBAAA,CACA,aAAA,CACA,6BAAA,CACA,iCAAA,CACA,wBAAA,CCMA,yBDZF,sBAQI,YAAA,CAAA,CAAA,yBARJ,sBAUI,aAAA,CACA,gBAAA,CAAA,CCPF,yBDJF,sBAaI,gBAAA,CAAA","sourcesContent":["@import \"../../../../assets/sass/base/_breakpoints.sass\"\n\n.header  \n  padding-top: 48px\n  padding-bottom: 48px  \n  display: block\n  justify-content: space-between\n  font-family: SuisseIntl !important\n  text-transform: uppercase\n  @include large-screen\n    display: flex\n  @include medium-screen  \n    display: block\n    padding-top: 30px    \n  @include small-screen  \n    padding-top: 10px    ","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `eLHmKHfZDzFTbBFpwK8M`
};
export default ___CSS_LOADER_EXPORT___;
