// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WPSvkaIryUYvoBTDqYuS{width:595px}@media(max-width: 768px){.WPSvkaIryUYvoBTDqYuS{width:420px}}.HJJBX8HAnbKM3oiupCIL{padding:0}.WPSvkaIryUYvoBTDqYuS ul{width:480px;table-layout:auto !important}@media(max-width: 768px){.WPSvkaIryUYvoBTDqYuS ul{width:385px;margin-left:-10px;font-size:12px}}`, "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Dab/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,WAAA,CCQA,yBDTF,sBAGI,WAAA,CAAA,CACJ,sBACE,SAAA,CACF,yBACE,WAAA,CACA,4BAAA,CCCA,yBDHF,yBAII,WAAA,CACA,iBAAA,CACA,cAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.assetDetails\n  width: 595px\n  @include mobile\n    width: 420px\n.header\n  padding: 0\n.assetDetails ul  \n  width: 480px\n  table-layout: auto !important      \n  @include mobile\n    width: 385px\n    margin-left: -10px\n    font-size: 12px","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n  @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin md\n  @media (min-width: #{$screen-md})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetDetails": `WPSvkaIryUYvoBTDqYuS`,
	"header": `HJJBX8HAnbKM3oiupCIL`
};
export default ___CSS_LOADER_EXPORT___;
