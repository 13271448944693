// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NzVBb1LD05qlemxO_NkY{padding-left:20px;padding-right:20px}@media(min-width: 992px){.NzVBb1LD05qlemxO_NkY{padding-left:100px;padding-right:100px}}@media(min-width: 1200px){.NzVBb1LD05qlemxO_NkY{padding-left:160px;padding-right:160px}}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/wrappers/Content/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,kBAAA,CCUA,yBDZF,sBAII,kBAAA,CACA,mBAAA,CAAA,CCWF,0BDhBF,sBAOI,kBAAA,CACA,mBAAA,CAAA","sourcesContent":["@import \"../../../assets/sass/base/_breakpoints.sass\"\n\n.content\n  padding-left: 20px\n  padding-right: 20px\n  @include large-screen\n    padding-left: 100px\n    padding-right: 100px \n  @include extra-large-screen\n    padding-left: 160px\n    padding-right: 160px \n","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `NzVBb1LD05qlemxO_NkY`
};
export default ___CSS_LOADER_EXPORT___;
