// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k4zZtCRRr_S0AOmpROw6{border-radius:24px;padding:24px 20px;border-width:2px;border-style:solid;border-color:var(--ct-accent-two);margin-right:32px;width:180px}.k4zZtCRRr_S0AOmpROw6.VsGv3pys6v6RS4c7AzJj{border-color:var(--ct-accent-one)}@media(max-width: 360px){.k4zZtCRRr_S0AOmpROw6{margin-bottom:15px}}.mgE0NAlMeDv66pTxD9Hm{color:#fff;margin-bottom:15px;font-size:14px}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/components/InfoColumn/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CACA,iBAAA,CACA,gBAAA,CACA,kBAAA,CACA,iCAAA,CACA,iBAAA,CACA,WAAA,CACA,2CACE,iCAAA,CCLF,yBDJF,sBAWI,kBAAA,CAAA,CACJ,sBACE,UAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":["@import \"src/client/themes/Test/assets/sass/base/_breakpoints.sass\"\n\n.infoColumn\n  border-radius: 24px\n  padding: 24px 20px\n  border-width: 2px\n  border-style: solid\n  border-color: var(--ct-accent-two)\n  margin-right: 32px\n  width: 180px\n  &.isPrimary\n    border-color: var(--ct-accent-one)\n  @include small-screen\n    margin-bottom: 15px \n.title\n  color: #fff\n  margin-bottom: 15px\n  font-size: 14px\n","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoColumn": `k4zZtCRRr_S0AOmpROw6`,
	"isPrimary": `VsGv3pys6v6RS4c7AzJj`,
	"title": `mgE0NAlMeDv66pTxD9Hm`
};
export default ___CSS_LOADER_EXPORT___;
