// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pIQQ7YLJUbaR07dm4QAA{border-radius:24px;background:#23262f;box-shadow:0px 64px 64px -48px rgba(31,47,70,.12)}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/wrappers/InfoBox/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,kBAAA,CACA,iDAAA","sourcesContent":[".box\n  border-radius: 24px\n  background: #23262F\n  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `pIQQ7YLJUbaR07dm4QAA`
};
export default ___CSS_LOADER_EXPORT___;
