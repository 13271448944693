// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s525GGMM6xyeflegFsle{background:#fafafa;padding:.75rem 2.75rem}.bJ8Q1qhIAwg3ixUu5FZ_{color:#000;font-weight:600;background-color:#fff;border:1px solid #e6e7e8 !important;cursor:pointer}.bJ8Q1qhIAwg3ixUu5FZ_:hover{color:#fff;background-color:var(--color-theme-dark)}.C14OFkrKLacxnMAwtxi6{color:#000;background-color:var(--color-theme-main);padding:0 16px}.C14OFkrKLacxnMAwtxi6:hover{background-color:var(--color-theme-main-dark)}.nMzh1dtZ3NCXEk6WJA9U{font-family:SuisseIntl !important;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/pages/Offers/pages/Offers/components/ModalAcceptOffer/components/FooterModal/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CACA,sBAAA,CACF,sBACE,UAAA,CACA,eAAA,CACA,qBAAA,CACA,mCAAA,CACA,cAAA,CACA,4BACE,UAAA,CACA,wCAAA,CACJ,sBACE,UAAA,CACA,wCAAA,CACA,cAAA,CACA,4BACE,6CAAA,CACJ,sBACE,iCAAA,CACA,wBAAA","sourcesContent":["@import \"@/client/assets/sass/base/spacing\"\n\n.footer\n  background: #FAFAFA\n  padding: $spacing-sm $spacing-xlg\n.btnCancel\n  color: #000\n  font-weight: 600\n  background-color: #fff\n  border: 1px solid #e6e7e8 !important\n  cursor: pointer\n  &:hover\n    color: #fff\n    background-color: var(--color-theme-dark) \n.btnClaim\n  color: #000\n  background-color: var(--color-theme-main)\n  padding: 0 16px\n  &:hover\n    background-color: var(--color-theme-main-dark)\n.btnOrgTxt\n  font-family: SuisseIntl !important\n  text-transform: uppercase"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `s525GGMM6xyeflegFsle`,
	"btnCancel": `bJ8Q1qhIAwg3ixUu5FZ_`,
	"btnClaim": `C14OFkrKLacxnMAwtxi6`,
	"btnOrgTxt": `nMzh1dtZ3NCXEk6WJA9U`
};
export default ___CSS_LOADER_EXPORT___;
