// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TyvvT6tlSPnMN8comG8i{padding:0}`, "",{"version":3,"sources":["webpack://./src/client/pages/Public/TempSat/components/modal/ModalPreviewAsset/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,SAAA","sourcesContent":[".header\n  padding: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `TyvvT6tlSPnMN8comG8i`
};
export default ___CSS_LOADER_EXPORT___;
