// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hSPau5IgI0z75lqNkc9Q{display:flex;justify-content:space-between;margin-bottom:40px}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/organisms/slideshows/MainSlideShow/components/Headers/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".header\n  display: flex\n  justify-content: space-between\n  margin-bottom: 40px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `hSPau5IgI0z75lqNkc9Q`
};
export default ___CSS_LOADER_EXPORT___;
