// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kiWYrgfDQWij93fj12jq{display:flex;font-size:20px}.kiWYrgfDQWij93fj12jq>*{margin-right:30px}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/pages/Bet/components/molecules/Header/components/Info/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,wBACE,iBAAA","sourcesContent":[".infoList\n  display: flex\n  font-size: 20px\n  > *\n    margin-right: 30px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoList": `kiWYrgfDQWij93fj12jq`
};
export default ___CSS_LOADER_EXPORT___;
