// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ewyAjGLmQDAkAElbCmE8 article{width:395px !important}.ewyAjGLmQDAkAElbCmE8 ul li button{background:var(--color-theme-main) !important;font-family:SuisseIntl !important;text-transform:uppercase;color:#000 !important}.ewyAjGLmQDAkAElbCmE8 ul li button:hover{color:#000 !important}.A_rYZohchDkF_wzmFnJd{background-color:#fff}`, "",{"version":3,"sources":["webpack://./src/client/pages/Offers/pages/RewardOffers/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,sBAAA,CACF,mCACE,6CAAA,CACA,iCAAA,CACA,wBAAA,CACA,qBAAA,CACA,yCACE,qBAAA,CACJ,sBACE,qBAAA","sourcesContent":[".offerOrganization article\n  width: 395px !important\n.offerOrganization ul li button\n  background: var(--color-theme-main) !important\n  font-family: SuisseIntl !important\n  text-transform: uppercase\n  color: #000 !important\n  &:hover    \n    color: #000 !important  \n.errorContainer    \n  background-color: #fff"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offerOrganization": `ewyAjGLmQDAkAElbCmE8`,
	"errorContainer": `A_rYZohchDkF_wzmFnJd`
};
export default ___CSS_LOADER_EXPORT___;
