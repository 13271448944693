// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeIH0AOvMpJUPUcm7UoT{display:flex;height:355px;width:100%;overflow-x:auto}.FeIH0AOvMpJUPUcm7UoT>*{margin-right:25px}@media(max-width: 360px){.FeIH0AOvMpJUPUcm7UoT{display:block;height:auto}}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/organisms/slideshows/MainSlideShow/components/SlidesGroup/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,YAAA,CACA,UAAA,CACA,eAAA,CACA,wBACE,iBAAA,CCFF,yBDJF,sBAQI,aAAA,CACA,WAAA,CAAA","sourcesContent":["@import \"src/client/themes/Test/assets/sass/base/_breakpoints.sass\"\n\n.slidesContainer\n  display: flex\n  height: 355px\n  width: 100%\n  overflow-x: auto\n  > *\n    margin-right: 25px\n  @include small-screen\n    display: block\n    height: auto  \n\n","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slidesContainer": `FeIH0AOvMpJUPUcm7UoT`
};
export default ___CSS_LOADER_EXPORT___;
