// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GhppUezhzPDkeJcEbVjn{display:flex;font-size:13px;color:var(--ct-text-color-primary);justify-content:space-between}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/components/1st/BasicHorseInformation/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,kCAAA,CACA,6BAAA","sourcesContent":[".horseInformation\n  display: flex\n  font-size: 13px\n  color: var(--ct-text-color-primary)\n  justify-content: space-between\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"horseInformation": `GhppUezhzPDkeJcEbVjn`
};
export default ___CSS_LOADER_EXPORT___;
