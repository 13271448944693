// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ue8zA0EQOXQ3wdO1zmQb{padding-top:30px;padding-bottom:30px}.HcD49J7YRxFcv1vAh7b8,.dsSxBRXHN9Z2X6STOMKn{width:100%;max-width:450px;margin-left:auto;margin-right:auto}.nlNGGXzSSMQ_X5cmDHAa{margin-bottom:10px;font-size:18px;color:#5fc3ac;text-align:center}.kO_6HBzb94jPWFSv7lG5{font-size:14px}.AuCP78jM2Eig0Flr8XO4{padding-left:25px}.VBaVV3SECmsjmJO4L3M0{margin-bottom:5px;list-style-type:disclosure-closed}.HcD49J7YRxFcv1vAh7b8{margin-bottom:50px}.dYam4qh2NtC14H01UYkl{text-align:center;font-weight:bold;color:var(--color-alert);margin-bottom:20px}.cgsicSH4Mcq5jSOJARH4{color:var(--color-alert);font-size:14px}.DanvwFcO3g4n3J1p7_xj{max-width:200px;width:100%}`, "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Sat/components/modal/ErrorMessageOnModal/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,mBAAA,CACF,4CAEE,UAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACF,sBACE,kBAAA,CACA,cAAA,CACA,aAAA,CACA,iBAAA,CACF,sBACE,cAAA,CACF,sBACE,iBAAA,CACF,sBACE,iBAAA,CACA,iCAAA,CACF,sBACE,kBAAA,CACF,sBACE,iBAAA,CACA,gBAAA,CACA,wBAAA,CACA,kBAAA,CACF,sBACE,wBAAA,CACA,cAAA,CACF,sBACE,eAAA,CACA,UAAA","sourcesContent":[".errorMessage\n  padding-top: 30px\n  padding-bottom: 30px\n.messageBox,\n.devBox\n  width: 100%\n  max-width: 450px\n  margin-left: auto\n  margin-right: auto\n.message\n  margin-bottom: 10px\n  font-size: 18px\n  color: #5fc3ac\n  text-align: center\n.details\n  font-size: 14px\n.detailsList\n  padding-left: 25px\n.itemList\n  margin-bottom: 5px\n  list-style-type: disclosure-closed\n.messageBox\n  margin-bottom: 50px\n.errorTitle\n  text-align: center\n  font-weight: bold\n  color: var(--color-alert)\n  margin-bottom: 20px\n.stack\n  color: var(--color-alert)\n  font-size: 14px\n.image\n  max-width: 200px\n  width: 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": `Ue8zA0EQOXQ3wdO1zmQb`,
	"messageBox": `HcD49J7YRxFcv1vAh7b8`,
	"devBox": `dsSxBRXHN9Z2X6STOMKn`,
	"message": `nlNGGXzSSMQ_X5cmDHAa`,
	"details": `kO_6HBzb94jPWFSv7lG5`,
	"detailsList": `AuCP78jM2Eig0Flr8XO4`,
	"itemList": `VBaVV3SECmsjmJO4L3M0`,
	"errorTitle": `dYam4qh2NtC14H01UYkl`,
	"stack": `cgsicSH4Mcq5jSOJARH4`,
	"image": `DanvwFcO3g4n3J1p7_xj`
};
export default ___CSS_LOADER_EXPORT___;
