// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hSl6I_ieUFCQZ_LeMl1G{display:flex}.hSl6I_ieUFCQZ_LeMl1G>*{margin-right:25px}.Nvg0bcpcGvTBSrrcCQAu{width:75px}.n14HlYB3HdX7ulKR8cs5{width:90px}.i0ImVarNphyadfCKLO4p{width:80px}.UkRNu1DADMyZQPoF0HsZ{color:#fff}.UkRNu1DADMyZQPoF0HsZ:hover{color:var(--color-theme-main);font-weight:600}.g1uQyeqwi3Htmd5KG_3p{width:100%;display:flex;justify-content:space-between}.GDS9sL3R2mBkWBt9je_s{width:30%}.GDS9sL3R2mBkWBt9je_s svg{margin-top:3px}.vWAOmBZEGPZpQwTRXLvP{width:70%}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/organisms/layout/Header/components/Menu/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,wBACE,iBAAA,CACJ,sBACE,UAAA,CACF,sBACE,UAAA,CACF,sBACI,UAAA,CACJ,sBACE,UAAA,CACA,4BACE,6BAAA,CACA,eAAA,CACJ,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CACF,sBACE,SAAA,CACA,0BACE,cAAA,CACJ,sBACE,SAAA","sourcesContent":[".menu\n  display: flex\n  & > *\n    margin-right: 25px\n.collectionsMenu\n  width: 75px\n.menuOffers\n  width: 90px\n.logoutMenu  \n    width: 80px\n.link\n  color: #fff\n  &:hover\n    color: var(--color-theme-main)\n    font-weight: 600\n.linkContainer    \n  width: 100%\n  display: flex\n  justify-content: space-between\n.iconCol\n  width: 30%  \n  svg\n    margin-top: 3px\n.textCol\n  width: 70%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `hSl6I_ieUFCQZ_LeMl1G`,
	"collectionsMenu": `Nvg0bcpcGvTBSrrcCQAu`,
	"menuOffers": `n14HlYB3HdX7ulKR8cs5`,
	"logoutMenu": `i0ImVarNphyadfCKLO4p`,
	"link": `UkRNu1DADMyZQPoF0HsZ`,
	"linkContainer": `g1uQyeqwi3Htmd5KG_3p`,
	"iconCol": `GDS9sL3R2mBkWBt9je_s`,
	"textCol": `vWAOmBZEGPZpQwTRXLvP`
};
export default ___CSS_LOADER_EXPORT___;
