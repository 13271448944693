// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a0uAMjhS2nvDz6zmgnxO{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/client/pages/Public/TempSat/components/AsideDetails/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".detailsBox\n  font-size: 14px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsBox": `a0uAMjhS2nvDz6zmgnxO`
};
export default ___CSS_LOADER_EXPORT___;
