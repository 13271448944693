// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tccKlqZ4Lqqf_LBhvjJk{background:var(--ct-tile-background-color);background-image:var(--ct-tile-background-image-url);border-width:6px;border-style:solid;border-color:var(--ct-accent-two);width:280px;font-size:15px;border-radius:20px}.Vajx7CDkHkZffY9xxwym{border-width:6px;border-style:solid;border-color:var(--ct-accent-one);padding:24px 20px;border-radius:12px}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/components/Card/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,0CAAA,CACA,oDAAA,CACA,gBAAA,CACA,kBAAA,CACA,iCAAA,CACA,WAAA,CACA,cAAA,CACA,kBAAA,CACF,sBACE,gBAAA,CACA,kBAAA,CACA,iCAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".infoBox\n  background: var(--ct-tile-background-color)\n  background-image: var(--ct-tile-background-image-url)\n  border-width: 6px\n  border-style: solid\n  border-color: var(--ct-accent-two)\n  width: 280px\n  font-size: 15px\n  border-radius: 20px\n.infoBoxContainer\n  border-width: 6px\n  border-style: solid\n  border-color: var(--ct-accent-one)\n  padding: 24px 20px\n  border-radius: 12px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBox": `tccKlqZ4Lqqf_LBhvjJk`,
	"infoBoxContainer": `Vajx7CDkHkZffY9xxwym`
};
export default ___CSS_LOADER_EXPORT___;
