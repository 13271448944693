// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zBpayhrig9ll6cFts8sR{margin-bottom:65px;text-align:center}.hJYwdjrNaxqsFUhzigqf{max-width:540px;width:100%;font-weight:bold;color:#000;background-color:var(--color-theme-main) !important}.hJYwdjrNaxqsFUhzigqf:hover{color:#fff;background-color:var(--color-theme-main-dark) !important}.ijHOPrUIbFCj9Y6DKbPq{margin-right:15px;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/atoms/buttons/ButtonBet/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,iBAAA,CACF,sBACE,eAAA,CACA,UAAA,CACA,gBAAA,CACA,UAAA,CACA,mDAAA,CACA,4BACE,UAAA,CACA,wDAAA,CACJ,sBACE,iBAAA,CACA,wBAAA","sourcesContent":[".buttonContainer\n  margin-bottom: 65px\n  text-align: center\n.button\n  max-width: 540px\n  width: 100%\n  font-weight: bold\n  color: #000\n  background-color: var(--color-theme-main) !important\n  &:hover\n    color: #fff\n    background-color: var(--color-theme-main-dark) !important\n.buttonLabel\n  margin-right: 15px\n  text-transform: uppercase\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": `zBpayhrig9ll6cFts8sR`,
	"button": `hJYwdjrNaxqsFUhzigqf`,
	"buttonLabel": `ijHOPrUIbFCj9Y6DKbPq`
};
export default ___CSS_LOADER_EXPORT___;
