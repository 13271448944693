// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HEHaetLgigznI5BNhHkd{display:flex;align-items:flex-start;overflow-x:scroll}.HEHaetLgigznI5BNhHkd>*{flex-shrink:0;flex-grow:0;margin-right:20px}@media(max-width: 360px){.HEHaetLgigznI5BNhHkd{display:block}}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/components/WrapperHorizontalScroll/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,wBACE,aAAA,CACA,WAAA,CACA,iBAAA,CCHF,yBDJF,sBASI,aAAA,CAAA","sourcesContent":["@import \"src/client/themes/Test/assets/sass/base/_breakpoints.sass\"\n\n.wrapperHorizontalScroll\n  display: flex\n  align-items: flex-start\n  overflow-x: scroll\n  & > *\n    flex-shrink: 0\n    flex-grow: 0\n    margin-right: 20px\n  @include small-screen\n    display: block  \n\n","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperHorizontalScroll": `HEHaetLgigznI5BNhHkd`
};
export default ___CSS_LOADER_EXPORT___;
