// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l0aIl7V3LJ7FbWc1AD83,.FgMmakDNlkHHy8pR03tB{border:2px solid var(--color-theme-dark-light)}.EHbGWqJ34IdIyduEG8bE{padding-bottom:14px;padding-left:16px;padding-right:16px;padding-top:14px}.Q0R2UNcwqebQo5dAQbhG{background:var(--color-theme-main);color:var(--color-theme-darkest);border-radius:8px}.FgMmakDNlkHHy8pR03tB{background:rgba(0,0,0,0);border-radius:100px;color:var(--color-theme-default-text)}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/assets/sass/base/_forms.sass","webpack://./src/client/themes/Test/components/atoms/buttons/ButtonDefault/styles.module.sass"],"names":[],"mappings":"AAAA,4CACE,8CAAA,CACF,sBACE,mBAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA,CCJF,sBACE,kCAAA,CACA,gCAAA,CACA,iBAAA,CACF,sBACE,wBAAA,CACA,mBAAA,CACA,qCAAA","sourcesContent":[".defFormBorders\n  border: 2px solid var(--color-theme-dark-light)\n.defFormPaddings\n  padding-bottom: 14px\n  padding-left: 16px\n  padding-right: 16px\n  padding-top: 14px\n","@import \"../../../../assets/sass/base/_forms\"\n\n.buttonAction\n  background: var(--color-theme-main)\n  color: var(--color-theme-darkest)\n  border-radius: 8px\n.buttonRounded\n  background: transparent\n  border-radius: 100px\n  color: var(--color-theme-default-text)\n  @extend .defFormBorders\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defFormBorders": `l0aIl7V3LJ7FbWc1AD83`,
	"buttonRounded": `FgMmakDNlkHHy8pR03tB`,
	"defFormPaddings": `EHbGWqJ34IdIyduEG8bE`,
	"buttonAction": `Q0R2UNcwqebQo5dAQbhG`
};
export default ___CSS_LOADER_EXPORT___;
