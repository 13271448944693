// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.afid3hUw07h6iE5rjAtF,.q2oBIEi__0p7O05N_W4n{width:100%;max-width:450px;margin-left:auto;margin-right:auto}.hgzPUAdL1zN17jpvq0pE{margin-bottom:10px;font-size:18px;color:var(--color-main);text-align:center}.YtrTzgoOvJhSJanLA2Ct{font-size:14px}.F9rWmjXYJMT0IwycnKW3{padding-left:25px}.UoGBpSTs8wcOWGFObL2t{margin-bottom:5px;list-style-type:disclosure-closed}.afid3hUw07h6iE5rjAtF{margin-bottom:50px}.vw6QpcVjIsdODnC_dzZw{text-align:center;font-weight:bold;color:var(--color-alert);margin-bottom:20px}.VnOLDna_dJIerSQgmWlK{color:var(--color-alert);font-size:14px}.Qbcl00S9LQGp_lt2Qa6F{max-width:200px;width:100%}`, "",{"version":3,"sources":["webpack://./src/client/pages/Redeem/pages/RewardOffer/components/OrganizationErrorMessage/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,UAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CACF,sBACE,kBAAA,CACA,cAAA,CACA,uBAAA,CACA,iBAAA,CACF,sBACE,cAAA,CACF,sBACE,iBAAA,CACF,sBACE,iBAAA,CACA,iCAAA,CACF,sBACE,kBAAA,CACF,sBACE,iBAAA,CACA,gBAAA,CACA,wBAAA,CACA,kBAAA,CACF,sBACE,wBAAA,CACA,cAAA,CACF,sBACE,eAAA,CACA,UAAA","sourcesContent":[".messageBox,\n.devBox\n  width: 100%\n  max-width: 450px\n  margin-left: auto\n  margin-right: auto  \n.message\n  margin-bottom: 10px\n  font-size: 18px\n  color: var(--color-main)\n  text-align: center\n.details\n  font-size: 14px\n.detailsList\n  padding-left: 25px\n.itemList\n  margin-bottom: 5px\n  list-style-type: disclosure-closed\n.messageBox\n  margin-bottom: 50px\n.errorTitle\n  text-align: center\n  font-weight: bold\n  color: var(--color-alert)\n  margin-bottom: 20px\n.stack\n  color: var(--color-alert)\n  font-size: 14px\n.image\n  max-width: 200px\n  width: 100%"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageBox": `afid3hUw07h6iE5rjAtF`,
	"devBox": `q2oBIEi__0p7O05N_W4n`,
	"message": `hgzPUAdL1zN17jpvq0pE`,
	"details": `YtrTzgoOvJhSJanLA2Ct`,
	"detailsList": `F9rWmjXYJMT0IwycnKW3`,
	"itemList": `UoGBpSTs8wcOWGFObL2t`,
	"errorTitle": `vw6QpcVjIsdODnC_dzZw`,
	"stack": `VnOLDna_dJIerSQgmWlK`,
	"image": `Qbcl00S9LQGp_lt2Qa6F`
};
export default ___CSS_LOADER_EXPORT___;
