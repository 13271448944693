// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TQ7pUSc6pLHH8vwQD9lS,.Ce3gKYrmwJrWfwCFnEwe{display:flex;justify-content:center}.TQ7pUSc6pLHH8vwQD9lS{margin-bottom:20px}.tJEUbx75A5YairIN40ol{margin-bottom:20px}.avCo5BHelqdh6eq2QD63{cursor:default}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/CollectionTemplate/components/CategoriesSections/component/Tabs/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,YAAA,CACA,sBAAA,CACF,sBACE,kBAAA,CACF,sBACE,kBAAA,CACF,sBACE,cAAA","sourcesContent":[".tabsContainer,\n.currentTab\n  display: flex\n  justify-content: center\n.tabsContainer\n  margin-bottom: 20px\n.componentContainer\n  margin-bottom: 20px\n.categorySelected\n  cursor: default"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsContainer": `TQ7pUSc6pLHH8vwQD9lS`,
	"currentTab": `Ce3gKYrmwJrWfwCFnEwe`,
	"componentContainer": `tJEUbx75A5YairIN40ol`,
	"categorySelected": `avCo5BHelqdh6eq2QD63`
};
export default ___CSS_LOADER_EXPORT___;
