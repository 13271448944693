// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uL4Q0Pr1TVevP6C_PXqK{display:flex}.uL4Q0Pr1TVevP6C_PXqK>*{margin-right:30px}.nXjk2zP345jsrjWL_kTQ{color:#fff}.nXjk2zP345jsrjWL_kTQ:hover{color:var(--color-theme-main);font-weight:600}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/GeneralTemplate/components/Header/components/Menu/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,wBACE,iBAAA,CACJ,sBACE,UAAA,CACA,4BACE,6BAAA,CACA,eAAA","sourcesContent":[".menu\n  display: flex\n  & > *\n    margin-right: 30px\n.link\n  color: #fff\n  &:hover\n    color: var(--color-theme-main)\n    font-weight: 600\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `uL4Q0Pr1TVevP6C_PXqK`,
	"link": `nXjk2zP345jsrjWL_kTQ`
};
export default ___CSS_LOADER_EXPORT___;
