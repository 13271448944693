// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zn0I7dTEdnJfc5DiXSWB{color:var(--color-theme-dark-light)}.aAwNmngu1EZhmRFtJIhf{color:var(--color-theme-default-text)}._1s2jBzJUsBcRp7qaXw3 .Zn0I7dTEdnJfc5DiXSWB{font-size:12px}._1s2jBzJUsBcRp7qaXw3 .aAwNmngu1EZhmRFtJIhf{font-size:14px}.C_aFUFCWVU3PORVbfRbB .Zn0I7dTEdnJfc5DiXSWB{font-size:16px;margin-bottom:10px}.C_aFUFCWVU3PORVbfRbB .aAwNmngu1EZhmRFtJIhf{font-size:24px;font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/molecules/items/InfoItem/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,mCAAA,CACF,sBACE,qCAAA,CAEA,4CACE,cAAA,CACF,4CACE,cAAA,CAEF,4CACE,cAAA,CACA,kBAAA,CACF,4CACE,cAAA,CACA,gBAAA","sourcesContent":[".label\n  color: var(--color-theme-dark-light)\n.value\n  color: var(--color-theme-default-text)\n.small\n  .label\n    font-size: 12px\n  .value\n    font-size: 14px\n.large\n  .label\n    font-size: 16px\n    margin-bottom: 10px\n  .value\n    font-size: 24px\n    font-weight: bold\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `Zn0I7dTEdnJfc5DiXSWB`,
	"value": `aAwNmngu1EZhmRFtJIhf`,
	"small": `_1s2jBzJUsBcRp7qaXw3`,
	"large": `C_aFUFCWVU3PORVbfRbB`
};
export default ___CSS_LOADER_EXPORT___;
