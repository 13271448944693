// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NTBvbF2Gdl30YSq34T2R{background:var(--ct-menu-background-color);display:flex;justify-content:space-between;align-items:center;width:100%}.SbeKmRkUJuZsl0AqpElO{background:var(--color-theme-dark-light);height:40px;margin:0 40px;width:2px}._Lmz9KZeL_Og4s2QF1ZW{width:168px}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/GeneralTemplate/components/Header/components/Navigation/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,0CAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CACF,sBACE,wCAAA,CACA,WAAA,CACA,aAAA,CACA,SAAA,CACF,sBACE,WAAA","sourcesContent":[".navigation\n  background: var(--ct-menu-background-color)\n  display: flex\n  justify-content: space-between\n  align-items: center\n  width: 100%\n.line\n  background: var(--color-theme-dark-light)\n  height: 40px\n  margin: 0 40px\n  width: 2px\n.logo\n  width: 168px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `NTBvbF2Gdl30YSq34T2R`,
	"line": `SbeKmRkUJuZsl0AqpElO`,
	"logo": `_Lmz9KZeL_Og4s2QF1ZW`
};
export default ___CSS_LOADER_EXPORT___;
