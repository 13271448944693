// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mmKGSbovPpEXgQiO8aYg,._SIgj4M_aG0ebBiUKn6g{font-weight:bold}.mmKGSbovPpEXgQiO8aYg{color:#fff;font-size:16px;text-transform:uppercase}._SIgj4M_aG0ebBiUKn6g{font-size:40px}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/organisms/slideshows/MainSlideShow/components/Titles/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,gBAAA,CACF,sBACE,UAAA,CACA,cAAA,CACA,wBAAA,CACF,sBACE,cAAA","sourcesContent":[".upperTitle,\n.title\n  font-weight: bold\n.upperTitle\n  color: #fff\n  font-size: 16px\n  text-transform: uppercase\n.title\n  font-size: 40px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upperTitle": `mmKGSbovPpEXgQiO8aYg`,
	"title": `_SIgj4M_aG0ebBiUKn6g`
};
export default ___CSS_LOADER_EXPORT___;
