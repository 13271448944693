// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lLSk28K9jjeWJRWnBguC{background:#fafafa;padding:.75rem 2.75rem}`, "",{"version":3,"sources":["webpack://./src/client/pages/Offers/pages/Offer/components/ModalRedemption/components/FooterModal/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CACA,sBAAA","sourcesContent":["@import \"@/client/assets/sass/base/spacing\"\n\n.footer\n  background: #FAFAFA\n  padding: $spacing-sm $spacing-xlg"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `lLSk28K9jjeWJRWnBguC`
};
export default ___CSS_LOADER_EXPORT___;
