// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V6mfABBQYBabWY5d_ONu{width:100%;border-radius:24px;overflow:hidden}.WTPTwtFSR5QNE6fotQw6{padding:30px 45px !important;overflow-x:auto}.BlJI4UU54pUAUIHZCqAo{display:flex;justify-content:space-between}.Pj0N_apm3Ks9Aw44GkC4{min-width:160px !important;margin-right:10px}.Pj0N_apm3Ks9Aw44GkC4:last-child{margin-right:0}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/pages/Bet/components/molecules/GeneralInfo/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,kBAAA,CACA,eAAA,CACF,sBACE,4BAAA,CACA,eAAA,CACF,sBACE,YAAA,CACA,6BAAA,CACF,sBACE,0BAAA,CACA,iBAAA,CACA,iCACE,cAAA","sourcesContent":[".detailsContainer\n  width: 100%\n  border-radius: 24px\n  overflow: hidden\n.generalInfo\n  padding: 30px 45px !important\n  overflow-x: auto  \n.detailsList\n  display: flex\n  justify-content: space-between\n.listItem\n  min-width: 160px !important  \n  margin-right: 10px\n  &:last-child\n    margin-right: 0  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detailsContainer": `V6mfABBQYBabWY5d_ONu`,
	"generalInfo": `WTPTwtFSR5QNE6fotQw6`,
	"detailsList": `BlJI4UU54pUAUIHZCqAo`,
	"listItem": `Pj0N_apm3Ks9Aw44GkC4`
};
export default ___CSS_LOADER_EXPORT___;
