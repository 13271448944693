// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.llFCzj3mBa3mmtKWgtOR{background:#000;min-height:100vh}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/templates/TemplateDefault/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,eAAA,CACA,gBAAA","sourcesContent":["@import \"src/client/themes/Test/assets/sass/base/_breakpoints.sass\"\n\n.content\n  background: #000000\n  min-height: 100vh\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `llFCzj3mBa3mmtKWgtOR`
};
export default ___CSS_LOADER_EXPORT___;
