// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sQ6PKAEEGy7QUetjoG_g{overflow:hidden}.TjidfcwCo3KEW5X43W3S{display:flex;justify-content:space-between;width:100%;overflow-x:auto}.mhdygSU180WxctcUED0m{width:155px;margin-right:10px;flex-shrink:0;flex-grow:0}@media(min-width: 992px){.mhdygSU180WxctcUED0m{margin-right:32px;width:350px}}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/pages/Bet/components/organisms/section-page/RecentRacing/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,eAAA,CACF,sBACE,YAAA,CACA,6BAAA,CACA,UAAA,CACA,eAAA,CACF,sBACE,WAAA,CACA,iBAAA,CACA,aAAA,CACA,WAAA,CCCA,yBDLF,sBAMI,iBAAA,CACA,WAAA,CAAA","sourcesContent":["@import \"../../../../../../assets/sass/base/_breakpoints.sass\"\n\n.container\n  overflow: hidden\n.boxes\n  display: flex\n  justify-content: space-between\n  width: 100%\n  overflow-x: auto\n.box\n  width: 155px\n  margin-right: 10px\n  flex-shrink: 0\n  flex-grow: 0\n  @include large-screen\n    margin-right: 32px\n    width: 350px","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sQ6PKAEEGy7QUetjoG_g`,
	"boxes": `TjidfcwCo3KEW5X43W3S`,
	"box": `mhdygSU180WxctcUED0m`
};
export default ___CSS_LOADER_EXPORT___;
