// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uiEygyEyQnL7eaRoS_Et{cursor:pointer}figcaption{color:#fff !important}.VJ0zUymRPi2tUMoDmzwd{position:relative;width:300px !important;height:250px !important;overflow:hidden !important;margin-bottom:10px;margin-right:20px;border-radius:16px}.qYVo_ry_60NAi1uzMkrs{width:300px !important;height:311px !important;position:absolute;top:-33px;left:0;right:0;bottom:0;object-fit:cover}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/organisms/slideshows/MainSlideShow/components/Slide/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACF,WACE,qBAAA,CACF,sBACE,iBAAA,CACA,sBAAA,CACA,uBAAA,CACA,0BAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CACF,sBACI,sBAAA,CACA,uBAAA,CACA,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,gBAAA","sourcesContent":["@import \"src/client/themes/Test/assets/sass/base/_breakpoints.sass\"\n\n.slideCursor\n  cursor: pointer\nfigcaption\n  color: #ffffff !important    \n.slideImgContainer\n  position: relative\n  width: 300px !important\n  height: 250px !important\n  overflow: hidden !important\n  margin-bottom: 10px\n  margin-right: 20px\n  border-radius: 16px  \n.slideImg\n    width: 300px !important\n    height: 311px !important\n    position: absolute\n    top: -33px\n    left: 0\n    right: 0\n    bottom: 0\n    object-fit: cover      \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slideCursor": `uiEygyEyQnL7eaRoS_Et`,
	"slideImgContainer": `VJ0zUymRPi2tUMoDmzwd`,
	"slideImg": `qYVo_ry_60NAi1uzMkrs`
};
export default ___CSS_LOADER_EXPORT___;
