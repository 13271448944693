// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ofm4pJgrGD4PU0CXGLHg{font-size:12px;padding:5px;border:1px solid var(--ct-accent-one);display:flex;flex-direction:column;justify-content:center;line-height:1}.lN4FlBGmRfyWihTZ822X{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/CollectionTemplate/components/CategoriesSections/component/CategoryContent/components/Section/component/Header/component/HeaderDetails/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,qCAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,aAAA,CACF,sBACE,YAAA,CACA,kBAAA","sourcesContent":[".headerDetails\n  font-size: 12px\n  padding: 5px\n  border: 1px solid var(--ct-accent-one)\n  display: flex\n  flex-direction: column\n  justify-content: center\n  line-height: 1\n.detail\n  display: flex\n  align-items: center\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerDetails": `ofm4pJgrGD4PU0CXGLHg`,
	"detail": `lN4FlBGmRfyWihTZ822X`
};
export default ___CSS_LOADER_EXPORT___;
