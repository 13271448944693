// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KwpLhqTjcooQpoduYXna{padding-right:50px !important;width:100%;font-family:SuisseIntl !important;text-transform:uppercase;border-color:#fff;color:#fff}.Y5bN8DvMWPONdz9uXSEA{position:relative;display:inline-flex;align-items:center}@media(max-width: 768px){.Y5bN8DvMWPONdz9uXSEA{margin-top:15px}}.ByNgBpC07Cqoqxzf_sEX{right:15px;position:absolute;color:#fff}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/molecules/forms/InputSearch/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,6BAAA,CACA,UAAA,CACA,iCAAA,CACA,wBAAA,CACA,iBAAA,CACA,UAAA,CACF,sBACE,iBAAA,CACA,mBAAA,CACA,kBAAA,CCDA,yBDFF,sBAKI,eAAA,CAAA,CACJ,sBACE,UAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.inputText\n  padding-right: 50px !important\n  width: 100%\n  font-family: SuisseIntl !important\n  text-transform: uppercase\n  border-color: #fff\n  color: #fff\n.inputSearch\n  position: relative\n  display: inline-flex\n  align-items: center\n  @include mobile\n    margin-top: 15px\n.icon\n  right: 15px\n  position: absolute\n  color: #fff\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n  @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin md\n  @media (min-width: #{$screen-md})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputText": `KwpLhqTjcooQpoduYXna`,
	"inputSearch": `Y5bN8DvMWPONdz9uXSEA`,
	"icon": `ByNgBpC07Cqoqxzf_sEX`
};
export default ___CSS_LOADER_EXPORT___;
