// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z2_x790gsu9OJnHHYcoj{color:#fff;margin-bottom:15px;font-size:20px;font-weight:normal}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/components/AssetCard/components/headers/GeneralHeader/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA","sourcesContent":[".title\n  color: #fff\n  margin-bottom: 15px\n  font-size: 20px\n  font-weight: normal\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `z2_x790gsu9OJnHHYcoj`
};
export default ___CSS_LOADER_EXPORT___;
