// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h3kaPiAzKIJVUneonD9S{overflow:hidden}.IZzdqiuXK4JZ6qlyOm54{display:flex;width:100%;overflow-x:auto}.uIJJKxstkrgekSkP_0QR{width:155px;margin-right:10px;flex-shrink:0;flex-grow:0}@media(min-width: 992px){.uIJJKxstkrgekSkP_0QR{margin-right:32px;width:160px}}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/pages/Bet/components/organisms/section-page/LifeTime/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,eAAA,CACF,sBACE,YAAA,CACA,UAAA,CACA,eAAA,CACF,sBACE,WAAA,CACA,iBAAA,CACA,aAAA,CACA,WAAA,CCEA,yBDNF,sBAMI,iBAAA,CACA,WAAA,CAAA","sourcesContent":["@import \"../../../../../../assets/sass/base/_breakpoints.sass\"\n\n.container\n  overflow: hidden\n.boxes\n  display: flex\n  width: 100%\n  overflow-x: auto\n.box\n  width: 155px\n  margin-right: 10px\n  flex-shrink: 0\n  flex-grow: 0\n  @include large-screen\n    margin-right: 32px\n    width: 160px\n","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `h3kaPiAzKIJVUneonD9S`,
	"boxes": `IZzdqiuXK4JZ6qlyOm54`,
	"box": `uIJJKxstkrgekSkP_0QR`
};
export default ___CSS_LOADER_EXPORT___;
