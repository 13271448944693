// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lYSrlBDRjFLn4hHV8NjH{cursor:pointer}.H1uXIjIRkYIVVNThFKms{font-weight:normal;color:var(--ct-text-color-primary);display:flex}.AalkrXC_JVuNhY8Bijs9{margin-right:10px;font-size:25px;display:flex;align-items:center}.tNXiDffZv7XwfvtEqAlp{font-size:20px;flex-grow:1;display:flex;justify-content:space-between}.zZJPwEodvTTkb_NfglMQ{padding-left:10px}.oov0Ycz45859SZ9zBVbg{background:var(--ct-accent-one);flex-grow:1;display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/CollectionTemplate/components/CategoriesSections/component/CategoryContent/components/Section/component/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACF,sBACE,kBAAA,CACA,kCAAA,CACA,YAAA,CACF,sBACE,iBAAA,CACA,cAAA,CACA,YAAA,CACA,kBAAA,CACF,sBACE,cAAA,CACA,WAAA,CACA,YAAA,CACA,6BAAA,CACF,sBACE,iBAAA,CACF,sBACE,+BAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".isCollapsible\n  cursor: pointer\n.titleSection\n  font-weight: normal\n  color: var(--ct-text-color-primary)\n  display: flex\n.collapsible\n  margin-right: 10px\n  font-size: 25px\n  display: flex\n  align-items: center\n.title\n  font-size: 20px\n  flex-grow: 1\n  display: flex\n  justify-content: space-between\n.hasPadding\n  padding-left: 10px\n.titleText\n  background: var(--ct-accent-one)\n  flex-grow: 1\n  display: flex\n  align-items: center\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isCollapsible": `lYSrlBDRjFLn4hHV8NjH`,
	"titleSection": `H1uXIjIRkYIVVNThFKms`,
	"collapsible": `AalkrXC_JVuNhY8Bijs9`,
	"title": `tNXiDffZv7XwfvtEqAlp`,
	"hasPadding": `zZJPwEodvTTkb_NfglMQ`,
	"titleText": `oov0Ycz45859SZ9zBVbg`
};
export default ___CSS_LOADER_EXPORT___;
