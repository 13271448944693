// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iZ2MNrUQ_JdXKouyY385{height:40px}.K2H49jEbqH8l8HgXoVuW{height:135px}.X9sw4EGOzxtW7Hu8Y5zG{height:270px}`, "",{"version":3,"sources":["webpack://./src/client/components/atoms/brand/Logo/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACF,sBACE,YAAA,CACF,sBACE,YAAA","sourcesContent":[".extra-small\n  height: 40px\n.small\n  height: 135px\n.regular\n  height: 270px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extra-small": `iZ2MNrUQ_JdXKouyY385`,
	"small": `K2H49jEbqH8l8HgXoVuW`,
	"regular": `X9sw4EGOzxtW7Hu8Y5zG`
};
export default ___CSS_LOADER_EXPORT___;
