// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G3CM0kmZK6bZ6naG1Kbg{font-size:40px;color:var(--color-theme-default-text)}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/pages/Bet/components/molecules/Header/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,qCAAA","sourcesContent":[".title\n  font-size: 40px\n  color: var(--color-theme-default-text)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `G3CM0kmZK6bZ6naG1Kbg`
};
export default ___CSS_LOADER_EXPORT___;
