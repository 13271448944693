// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MRkK8JCzDX0l185t7oUB{display:flex;flex-wrap:wrap}@media(max-width: 1048px){.MRkK8JCzDX0l185t7oUB{justify-content:center}}`, "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Collection/components/CollectionDataList/styles.module.sass","webpack://./src/client/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,cAAA,CCWA,0BDbF,sBAII,sBAAA,CAAA","sourcesContent":["@import \"@/client/assets/sass/base/breakpoints\"\n\n.cardsContainer\n  display:  flex\n  flex-wrap: wrap\n  @include small-pc\n    justify-content: center\n","$screen-xs: 440px\n$screen-sm: 576px\n$screen-md: 768px\n$screen-lg: 992px\n$screen-xl: 1200px\n\n@mixin small-mobile\n  @media (max-width: #{$screen-xs})\n    @content\n\n@mixin mobile\n  @media (max-width: #{$screen-md})\n    @content\n\n@mixin small-pc\n  @media (max-width: #{1048px})\n    @content\n\n@mixin md\n  @media (min-width: #{$screen-md})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardsContainer": `MRkK8JCzDX0l185t7oUB`
};
export default ___CSS_LOADER_EXPORT___;
