// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v7G4_iCjQmeqHl0O_gtl{padding:24px 20px}.cpIgtWgGYnRnYyJ5YnAL{margin-bottom:12px}.cpIgtWgGYnRnYyJ5YnAL:last-child{margin-bottom:0}.lA7PumvCWO4G2nRp3jGi{color:#fff;margin-bottom:15px;font-size:14px}.qddpz_BeZoe5mQHawbKh{font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/molecules/boxes/DetailsBox/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACF,sBACE,kBAAA,CACA,iCACE,eAAA,CACJ,sBACE,UAAA,CACA,kBAAA,CACA,cAAA,CACF,sBACE,gBAAA","sourcesContent":["@import \"../../../../assets/sass/base/_breakpoints.sass\"\n\n.infoBox\n  padding: 24px 20px\n.itemList\n  margin-bottom: 12px\n  &:last-child\n    margin-bottom: 0\n.title\n  color: #fff\n  margin-bottom: 15px\n  font-size: 14px\n.value\n  font-weight: bold\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBox": `v7G4_iCjQmeqHl0O_gtl`,
	"itemList": `cpIgtWgGYnRnYyJ5YnAL`,
	"title": `lA7PumvCWO4G2nRp3jGi`,
	"value": `qddpz_BeZoe5mQHawbKh`
};
export default ___CSS_LOADER_EXPORT___;
