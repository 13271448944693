// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.biHthvMRUiudXN0gZHvl{width:300px}`, "",{"version":3,"sources":["webpack://./src/client/components/molecules/asset/filters/SideBarAssetsFilters/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,WAAA","sourcesContent":[".sb-asset\n  width: 300px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sb-asset": `biHthvMRUiudXN0gZHvl`
};
export default ___CSS_LOADER_EXPORT___;
