// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yBLq4ZmDRA8SARlH8liR{display:flex;justify-content:space-between;align-items:center;width:100%}@media(min-width: 992px){.yBLq4ZmDRA8SARlH8liR{width:auto}}.AYZ1hCwFDPkz_tNpk6ej{background:#fff;height:40px;margin:0 15px;width:2px}.mcqJmfoEpTkdhO3FJTji{height:40px}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/organisms/layout/Header/components/Navigation/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,UAAA,CCQA,yBDZF,sBAMI,UAAA,CAAA,CACJ,sBACE,eAAA,CACA,WAAA,CACA,aAAA,CACA,SAAA,CACF,sBACE,WAAA","sourcesContent":["@import \"../../../../../../assets/sass/base/_breakpoints.sass\"\n\n.navigation\n  display: flex\n  justify-content: space-between\n  align-items: center\n  width: 100%\n  @include large-screen\n    width: auto\n.line\n  background: #fff\n  height: 40px\n  margin: 0 15px\n  width: 2px\n.logo\n  height: 40px","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `yBLq4ZmDRA8SARlH8liR`,
	"line": `AYZ1hCwFDPkz_tNpk6ej`,
	"logo": `mcqJmfoEpTkdhO3FJTji`
};
export default ___CSS_LOADER_EXPORT___;
