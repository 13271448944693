// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a_CzH99mILP5J71iClEq{color:var(--color-theme-dark-light)}.uOPyoOmCc32QBBWbQekw{color:var(--color-theme-default-text);white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.IfLSG6HU0JnbBmIoSK_Q .a_CzH99mILP5J71iClEq{font-size:12px}.IfLSG6HU0JnbBmIoSK_Q .uOPyoOmCc32QBBWbQekw{font-size:14px}.a7vgzC9Kx2MsWs4Q8DK0 .a_CzH99mILP5J71iClEq{font-size:16px;margin-bottom:10px}.a7vgzC9Kx2MsWs4Q8DK0 .uOPyoOmCc32QBBWbQekw{font-size:24px;font-weight:bold}.usEKMqh0mC2K2Q6r8eKV{margin-bottom:12px}.usEKMqh0mC2K2Q6r8eKV:last-child{margin-bottom:0}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/components/InfoItem/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,mCAAA,CACF,sBACE,qCAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CAEA,4CACE,cAAA,CACF,4CACE,cAAA,CAEF,4CACE,cAAA,CACA,kBAAA,CACF,4CACE,cAAA,CACA,gBAAA,CACJ,sBACE,kBAAA,CACA,iCACE,eAAA","sourcesContent":[".label\n  color: var(--color-theme-dark-light)\n.value\n  color: var(--color-theme-default-text)\n  white-space: nowrap\n  text-overflow: ellipsis\n  overflow: hidden\n.small\n  .label\n    font-size: 12px\n  .value\n    font-size: 14px\n.large\n  .label\n    font-size: 16px\n    margin-bottom: 10px\n  .value\n    font-size: 24px\n    font-weight: bold\n.regular\n  margin-bottom: 12px\n  &:last-child\n    margin-bottom: 0\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `a_CzH99mILP5J71iClEq`,
	"value": `uOPyoOmCc32QBBWbQekw`,
	"small": `IfLSG6HU0JnbBmIoSK_Q`,
	"large": `a7vgzC9Kx2MsWs4Q8DK0`,
	"regular": `usEKMqh0mC2K2Q6r8eKV`
};
export default ___CSS_LOADER_EXPORT___;
