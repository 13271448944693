// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vfcDM3nxn32PY0C98tlt,.uda0O9CN33XSfBDTrMEv{display:block;margin:0 auto 15px;height:auto}.vfcDM3nxn32PY0C98tlt{width:230px;height:auto}.uda0O9CN33XSfBDTrMEv{width:270px}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/GeneralTemplate/components/Header/components/Logos/styles.module.sass"],"names":[],"mappings":"AAAA,4CAEE,aAAA,CACA,kBAAA,CACA,WAAA,CACF,sBACE,WAAA,CACA,WAAA,CACF,sBACE,WAAA","sourcesContent":[".offerLogo,\n.tokenLogo\n  display: block\n  margin: 0 auto 15px\n  height: auto\n.offerLogo\n  width: 230px\n  height: auto\n.tokenLogo\n  width: 270px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"offerLogo": `vfcDM3nxn32PY0C98tlt`,
	"tokenLogo": `uda0O9CN33XSfBDTrMEv`
};
export default ___CSS_LOADER_EXPORT___;
