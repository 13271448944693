// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iCaCMHgsmoKwsBNrwcPa{font-size:13px}`, "",{"version":3,"sources":["webpack://./src/client/pages/Assets/components/ShareNotification/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA","sourcesContent":[".shareNotification\n  font-size: 13px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareNotification": `iCaCMHgsmoKwsBNrwcPa`
};
export default ___CSS_LOADER_EXPORT___;
