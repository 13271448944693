// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Yajf7nAtrBYanoMGDEam ul li a{background-color:var(--color-theme-main) !important;border-color:var(--color-theme-main) !important;color:#000 !important}.Yajf7nAtrBYanoMGDEam ul li a:hover{background-color:var(--color-theme-main-dark) !important;color:#000 !important}.Yajf7nAtrBYanoMGDEam ul li a[aria-current=page]{background-color:var(--color-theme-main-dark) !important;border-color:var(--color-theme-main-dark) !important}.Yajf7nAtrBYanoMGDEam nav a:hover{border-color:var(--color-theme-main) !important;color:#fff !important;font-weight:600}.E4cwt3k9JgeMUnZ9aUSQ>div>div:first-child a{background:var(--color-theme-main) !important;font-family:SuisseIntl !important;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/pages/Assets/pages/Assets/styles.module.sass"],"names":[],"mappings":"AAAA,8BACE,mDAAA,CACA,+CAAA,CACA,qBAAA,CACA,oCACE,wDAAA,CACA,qBAAA,CACJ,iDACE,wDAAA,CACA,oDAAA,CAEA,kCACE,+CAAA,CACA,qBAAA,CACA,eAAA,CAII,4CACE,6CAAA,CACA,iCAAA,CACA,wBAAA","sourcesContent":[".paginationContainerOrg ul li a\n  background-color: var(--color-theme-main) !important\n  border-color: var(--color-theme-main) !important\n  color: #000 !important\n  &:hover\n    background-color: var(--color-theme-main-dark) !important\n    color: #000 !important  \n.paginationContainerOrg ul li a[aria-current='page']\n  background-color: var(--color-theme-main-dark) !important\n  border-color: var(--color-theme-main-dark) !important  \n.paginationContainerOrg nav a\n  &:hover\n    border-color: var(--color-theme-main) !important\n    color: #fff !important\n    font-weight: 600\n.orgPage\n  > div\n    > div:first-child\n        a\n          background: var(--color-theme-main) !important\n          font-family: SuisseIntl !important\n          text-transform: uppercase\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainerOrg": `Yajf7nAtrBYanoMGDEam`,
	"orgPage": `E4cwt3k9JgeMUnZ9aUSQ`
};
export default ___CSS_LOADER_EXPORT___;
