// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DEGzaa2yWcjrbQosxmHM{min-width:80px;border-radius:100px;border-width:2px;border-style:solid;border-color:var(--ct-accent-two);display:inline-block;padding:0;line-height:1;background:rgba(0,0,0,0);cursor:pointer}.DEGzaa2yWcjrbQosxmHM.sdW3LGf9CCcXExv5CMV3{border-width:4px;padding:8px}.ODPYdM5M1lSBGgzPt0vg{background:rgba(0,0,0,0);border-color:var(--ct-accent-one);border-radius:100px;border-style:solid;border-width:3px;padding:5px 10px;color:#fff;font-size:14px}.ODPYdM5M1lSBGgzPt0vg.dfRlHIcCNNkQMOTtitak{font-size:30px}.ODPYdM5M1lSBGgzPt0vg.sdW3LGf9CCcXExv5CMV3{align-items:center;display:flex;font-size:35px;min-height:80px;padding:12px 15px}.h1pN98AreZZ8jRX9aHRv{display:flex}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/components/DefaultButton/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,mBAAA,CACA,gBAAA,CACA,kBAAA,CACA,iCAAA,CACA,oBAAA,CACA,SAAA,CACA,aAAA,CACA,wBAAA,CACA,cAAA,CACA,2CACE,gBAAA,CACA,WAAA,CACJ,sBACE,wBAAA,CACA,iCAAA,CACA,mBAAA,CACA,kBAAA,CACA,gBAAA,CACA,gBAAA,CACA,UAAA,CACA,cAAA,CACA,2CACE,cAAA,CACF,2CACE,kBAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACJ,sBACE,YAAA","sourcesContent":[".button\n  min-width: 80px\n  border-radius: 100px\n  border-width: 2px\n  border-style: solid\n  border-color: var(--ct-accent-two)\n  display: inline-block\n  padding: 0\n  line-height: 1\n  background: transparent\n  cursor: pointer\n  &.isBigger\n    border-width: 4px\n    padding: 8px\n.buttonContainer\n  background: transparent\n  border-color: var(--ct-accent-one)\n  border-radius: 100px\n  border-style: solid\n  border-width: 3px\n  padding: 5px 10px\n  color: #fff\n  font-size: 14px\n  &.isBig\n    font-size: 30px\n  &.isBigger\n    align-items: center\n    display: flex\n    font-size: 35px\n    min-height: 80px\n    padding: 12px 15px\n.contentWithArrow\n  display: flex\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `DEGzaa2yWcjrbQosxmHM`,
	"isBigger": `sdW3LGf9CCcXExv5CMV3`,
	"buttonContainer": `ODPYdM5M1lSBGgzPt0vg`,
	"isBig": `dfRlHIcCNNkQMOTtitak`,
	"contentWithArrow": `h1pN98AreZZ8jRX9aHRv`
};
export default ___CSS_LOADER_EXPORT___;
