// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a9cyIrm1_YcK1BAFkRvf,.pP1ITIvOGoSXNwjFk6dp{border:2px solid var(--color-theme-dark-light)}.Tpv00vs6z2FQANswyDn6,.pP1ITIvOGoSXNwjFk6dp{padding-bottom:14px;padding-left:16px;padding-right:16px;padding-top:14px}.pP1ITIvOGoSXNwjFk6dp{background:rgba(0,0,0,0);border-radius:12px;color:var(--color-theme-dark-light)}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/assets/sass/base/_forms.sass","webpack://./src/client/themes/Test/components/atoms/forms/InputText/styles.module.sass"],"names":[],"mappings":"AAAA,4CACE,8CAAA,CACF,4CACE,mBAAA,CACA,iBAAA,CACA,kBAAA,CACA,gBAAA,CCJF,sBACE,wBAAA,CACA,kBAAA,CACA,mCAAA","sourcesContent":[".defFormBorders\n  border: 2px solid var(--color-theme-dark-light)\n.defFormPaddings\n  padding-bottom: 14px\n  padding-left: 16px\n  padding-right: 16px\n  padding-top: 14px\n","@import \"../../../../assets/sass/base/_forms\"\n\n.inputText\n  background: transparent\n  border-radius: 12px\n  color: var(--color-theme-dark-light)\n  @extend .defFormBorders\n  @extend .defFormPaddings\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defFormBorders": `a9cyIrm1_YcK1BAFkRvf`,
	"inputText": `pP1ITIvOGoSXNwjFk6dp`,
	"defFormPaddings": `Tpv00vs6z2FQANswyDn6`
};
export default ___CSS_LOADER_EXPORT___;
