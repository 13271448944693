// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ka70g_cGVPUvQi9Pdlh6{border-radius:4px;padding:3px 8px;text-transform:uppercase;border-width:1px;border-style:solid;font-size:12px}.keZsL8YWgGj3wb5zj6xi{border-color:var(--color-theme-main);color:var(--color-theme-main)}.C06V6RuuKaldQ1lE5vLa{border-color:#fff;color:#fff}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/TokenDetailsTemplate/components/AssetsSection/components/DefaultLabel/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,eAAA,CACA,wBAAA,CACA,gBAAA,CACA,kBAAA,CACA,cAAA,CACF,sBACE,oCAAA,CACA,6BAAA,CACF,sBACE,iBAAA,CACA,UAAA","sourcesContent":[".label\n  border-radius: 4px\n  padding: 3px 8px\n  text-transform: uppercase\n  border-width: 1px\n  border-style: solid\n  font-size: 12px\n.featured\n  border-color: var(--color-theme-main)\n  color: var(--color-theme-main)\n.default\n  border-color: #fff\n  color: #Fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `ka70g_cGVPUvQi9Pdlh6`,
	"featured": `keZsL8YWgGj3wb5zj6xi`,
	"default": `C06V6RuuKaldQ1lE5vLa`
};
export default ___CSS_LOADER_EXPORT___;
