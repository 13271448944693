// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qvqQWe3lvRGzvNfe30lx{padding:.75rem 0}.nDPsC1bGpLvb2gZQI0qh,.qfYNakeSNARQg7TeNxrO{padding:.75rem;color:#fff}.qfYNakeSNARQg7TeNxrO{display:block;border-bottom:1px solid rgba(255,255,255,0.1019607843)}.qfYNakeSNARQg7TeNxrO:hover{color:var(--color-theme-main-dark)}.WesyG6SxIrQosCApjz7U{width:100%;padding:.375rem}`, "",{"version":3,"sources":["webpack://./src/client/themes/Test/components/organisms/layout/Header/components/Navigation/components/MenuMobile/styles.module.sass","webpack://./src/client/assets/sass/base/_units.sass"],"names":[],"mappings":"AAEA,sBACE,gBAAA,CACF,4CAEE,cCFG,CDGH,UAAA,CACF,sBACE,aAAA,CACA,sDAAA,CACA,4BACE,kCAAA,CACJ,sBACE,UAAA,CACA,eCdK","sourcesContent":["@import \"@/client/assets/sass/base/_spacing.sass\"\n\n.menuMobile  \n  padding: $spacing-sm 0    \n.itemListSearch,\n.itemLink\n  padding: $spacing-sm\n  color: #fff\n.itemLink\n  display: block\n  border-bottom: 1px solid rgba(255, 255, 255, 0.1019607843)\n  &:hover\n    color: var(--color-theme-main-dark)\n.searchBox\n  width: 100%\n  padding: $spacing-xs","$u1: 0.25rem      // 4px\n$u1-5: 0.375rem   // 6px\n$u2: 0.5rem       // 8px\n$u2-5: 0.625rem   // 10px\n$u3: 0.75rem      // 12px\n$u3-5: 0.875rem   // 14px\n$u4: 1rem         // 16px\n$u5: 1.25rem      // 20px\n$u6: 1.5rem       // 24px\n$u7: 1.75rem      // 28px\n$u8: 2rem         // 32px\n$u9: 2.25rem      // 36px\n$u10: 2.5rem      // 40px\n$u11: 2.75rem     // 44px\n$u12: 3rem        // 48px\n$u13: 3.25rem     // 52px\n$u14: 3.5rem      // 56px\n$u15: 3.75rem     // 60px\n$u16: 4rem        // 64px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuMobile": `qvqQWe3lvRGzvNfe30lx`,
	"itemListSearch": `nDPsC1bGpLvb2gZQI0qh`,
	"itemLink": `qfYNakeSNARQg7TeNxrO`,
	"searchBox": `WesyG6SxIrQosCApjz7U`
};
export default ___CSS_LOADER_EXPORT___;
