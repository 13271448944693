// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SexNz7JVxiD70SMECEpN{background:#fafafa;padding:.75rem 2.75rem}.adeAxfq2pTcp7Gjds2Ve{color:#000;background-color:#fff;font-weight:600;border:1px solid #e6e7e8 !important;cursor:pointer}.adeAxfq2pTcp7Gjds2Ve:hover{color:#fff;background-color:var(--color-theme-dark)}.yBQDHU0Ik4WsPNkV5U6L{color:#000;background-color:var(--color-theme-main);font-weight:600;padding:0 16px}.yBQDHU0Ik4WsPNkV5U6L:hover{background-color:var(--color-theme-main-dark);color:#fff}.q_D_xtqqXMA0uLHnNWXw{font-family:SuisseIntl !important;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/pages/Redeem/pages/CollectionOffer/components/ModalAcceptCollectionOffer/components/FooterModal/styles.module.sass"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CACA,sBAAA,CACF,sBACE,UAAA,CACA,qBAAA,CACA,eAAA,CACA,mCAAA,CACA,cAAA,CACA,4BACE,UAAA,CACA,wCAAA,CACJ,sBACE,UAAA,CACA,wCAAA,CACA,eAAA,CACA,cAAA,CACA,4BACE,6CAAA,CACA,UAAA,CACJ,sBACE,iCAAA,CACA,wBAAA","sourcesContent":["@import \"@/client/assets/sass/base/spacing\"\n\n.footer\n  background: #FAFAFA\n  padding: $spacing-sm $spacing-xlg\n.btnCancelOrg\n  color: #000\n  background-color: #fff\n  font-weight: 600\n  border: 1px solid #e6e7e8 !important\n  cursor: pointer\n  &:hover\n    color: #fff\n    background-color: var(--color-theme-dark) \n.btnClaimOrg\n  color: #000\n  background-color: var(--color-theme-main)\n  font-weight: 600\n  padding: 0 16px\n  &:hover\n    background-color: var(--color-theme-main-dark)\n    color: #fff\n.btnOrgTxt\n  font-family: SuisseIntl !important\n  text-transform: uppercase    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `SexNz7JVxiD70SMECEpN`,
	"btnCancelOrg": `adeAxfq2pTcp7Gjds2Ve`,
	"btnClaimOrg": `yBQDHU0Ik4WsPNkV5U6L`,
	"btnOrgTxt": `q_D_xtqqXMA0uLHnNWXw`
};
export default ___CSS_LOADER_EXPORT___;
