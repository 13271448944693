// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bATVMP2DF2SSm73YF9hI{max-width:595px}.N5TJUUbDFc5_FhUaCOAs{padding:0}`, "",{"version":3,"sources":["webpack://./src/client/pages/Public/TempDab/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACF,sBACE,SAAA","sourcesContent":[".assetDetails\n  max-width: 595px\n.header\n  padding: 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assetDetails": `bATVMP2DF2SSm73YF9hI`,
	"header": `N5TJUUbDFc5_FhUaCOAs`
};
export default ___CSS_LOADER_EXPORT___;
