// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ov1KG1XVk1IV3yZdoVUu{position:relative;width:320px !important;height:255px !important;overflow:hidden !important;border-radius:16px}.ov1KG1XVk1IV3yZdoVUu img{width:320px !important;height:311px !important;position:absolute;top:-28px;left:0;right:0;bottom:-20px}@media(max-width: 360px){.ov1KG1XVk1IV3yZdoVUu{width:245px !important;height:230px !important}.ov1KG1XVk1IV3yZdoVUu img{width:245px !important;height:282px !important;top:-27px}}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/TokenDetailsTemplate/styles.module.sass","webpack://./src/client/themes/Test/assets/sass/base/_breakpoints.sass"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,sBAAA,CACA,uBAAA,CACA,0BAAA,CACA,kBAAA,CACA,0BACE,sBAAA,CACA,uBAAA,CACA,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,YAAA,CCTF,yBDJF,sBAeI,sBAAA,CACA,uBAAA,CACA,0BACE,sBAAA,CACA,uBAAA,CACA,SAAA,CAAA","sourcesContent":["@import \"src/client/themes/Test/assets/sass/base/_breakpoints.sass\"\n\n.imageContainer\n  position: relative\n  width: 320px !important\n  height: 255px !important\n  overflow: hidden !important\n  border-radius: 16px\n  img\n    width: 320px !important\n    height: 311px !important\n    position: absolute\n    top: -28px\n    left: 0\n    right: 0\n    bottom: -20px\n  @include small-screen      \n    width: 245px !important\n    height: 230px !important\n    img\n      width: 245px !important\n      height: 282px !important\n      top: -27px","$screen-small: 360px\n$screen-medium: 768px\n$screen-large: 992px\n$screen-extra-large: 1200px\n\n@mixin small-screen\n  @media (max-width: #{$screen-small})\n    @content\n\n@mixin medium-screen\n  @media (max-width: #{$screen-medium})\n    @content\n\n@mixin large-screen\n  @media (min-width: #{$screen-large})\n    @content\n\n@mixin extra-large-screen\n  @media (min-width: #{$screen-extra-large})\n    @content\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": `ov1KG1XVk1IV3yZdoVUu`
};
export default ___CSS_LOADER_EXPORT___;
