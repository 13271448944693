// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EYIi_24HEFx89vESECzX{margin-bottom:10px}.SwIDQRR6CYE3hMnSHt2k.fUoHbEHhkyLCX828WT1_{padding-left:25px}.m_0qIfAntRwoccW3CHg1{padding:15px}`, "",{"version":3,"sources":["webpack://./src/client/components/templates/campaign-templates/CollectionTemplate/components/CategoriesSections/component/CategoryContent/components/Section/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,2CACE,iBAAA,CACJ,sBACE,YAAA","sourcesContent":[".section\n  margin-bottom: 10px\n.container\n  &.hasPadding\n    padding-left: 25px\n.scrollContainer\n  padding: 15px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `EYIi_24HEFx89vESECzX`,
	"container": `SwIDQRR6CYE3hMnSHt2k`,
	"hasPadding": `fUoHbEHhkyLCX828WT1_`,
	"scrollContainer": `m_0qIfAntRwoccW3CHg1`
};
export default ___CSS_LOADER_EXPORT___;
